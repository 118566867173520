import styled from '@emotion/styled';
import { Loader } from '@mantine/core';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { store } from '~/pages/heineken_template/_private/store';
import { asia71178_store } from '../asia71178_store';
export const TrendScore = memo(function TrendScore() {
    const scoreData = useSnapshot(asia71178_store).scores;
    const reverseData = [...scoreData].reverse();
    const uniqueDates = [];
    const data = reverseData.reduce((filteredData, item) => {
        if (!uniqueDates.includes(item.date)) {
            uniqueDates.push(item.date);
            filteredData.push(item);
        }
        return filteredData;
    }, []);
    const charting = useSnapshot(store.charting);
    const loadingState = data.map(s => s.date)?.includes('Invalid Date');
    const stockSymbol = charting.symbol.match(/^[\d]{4}$/);
    const enableInterval = charting.interval === '1D' || charting.interval === '60';
    return (<styleds.container>
      {!stockSymbol || !enableInterval ? (<span> {!stockSymbol ? '僅支援股票商品' : '請使用日線與60分鐘週期'}</span>) : (<div css={fill_horizontal_all_center}>
          {loadingState ? (<Loader variant='dots'/>) : (<div css={fill_horizontal_all_center}>
              {data.slice(-5).map((item, index) => {
                    return (<ScoreItem key={index} date={item.date} score={item.score}/>);
                })}
            </div>)}
        </div>)}
    </styleds.container>);
});
const ScoreItem = memo(function ScoreItem(props) {
    const scoreFill = () => {
        if (props.score === 5) {
            return '#e45751';
        }
        else if (props.score === 4) {
            return '#e5a2a0';
        }
        else {
            return '#bbbbbb';
        }
    };
    return (<styleds.itemContainer>
      <styleds.dateContent>{props.date.includes('Date') ? '-' : props.date}</styleds.dateContent>
      <styleds.valueContent fill={scoreFill()}>{props.score}</styleds.valueContent>
    </styleds.itemContainer>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_all_center};
    width: 100%;
    padding: 4px;
    gap: 4px;
  `,
    itemContainer: styled.div `
    ${fill_vertical_all_center};
    cursor: pointer;
    &:hover {
      background-color: #f4f4f4;
      border-radius: 4px;
    }
  `,
    dateContent: styled.span `
    color: #353535;
    font-size: 14px;
  `,
    valueContent: styled.span `
    ${fill_vertical_all_center};
    background-color: ${props => props.fill};
    border-radius: 4px;
    border: 1px solid #cccccc;
    color: #ffffff;
    font-weight: bold;
    border-radius: 5px;
    height: 30px;
    width: 30px;
  `,
};
