import { SessionType } from '~/modules/SDK/Chart2/SessionType';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { asia71178_store } from '~/pages/asia71178/asia71178_store';
import dayAPI from '~/utils/dayAPI';
export const asia71178_value = createIndicator({
    id: 'asia71178-value',
    displayName: 'value',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            //#region Init varaibles
            let score = 0;
            const sessionType = context.symbol.ticker === 'TX-1'
                ? SessionType.TAIFEX
                : context.symbol.ticker === 'TXAM-1'
                    ? SessionType.TAIFEX_AM
                    : SessionType.TWSE;
            const resolution = context.symbol.period;
            const close = this.ohlc.closeArray;
            const _updateScore = (score_) => {
                asia71178_store.scores.unshift({
                    score: score_,
                    date: dayAPI(this.PineJS.Std.time(context)).format('MM/DD'),
                });
                if (asia71178_store.scores.length > 7)
                    asia71178_store.scores.pop();
            };
            close.get(100);
            //#endregion
            //#region Indicator Parameters
            const wmaLength = 10; //9
            const kdLength = 8; //7
            const rsvt = 8; //7
            const kt = 12; //11
            const bbandLength = 12; //11
            const stds = 2; //7
            //#endregion
            //#region Indicators
            const wma = resolution === '1D'
                ? context.new_var(this.wma(close, wmaLength))
                : context.new_var(this.wma(close, wmaLength * 5));
            const stochastic = resolution === '1D'
                ? this.stochastic(kdLength, rsvt, kt)
                : this.stochastic(kdLength * 5, rsvt * 5, kt * 5);
            const bband = resolution === '1D'
                ? this.bollingerBand(close, bbandLength, stds)
                : this.bollingerBand(close, bbandLength * 5, stds);
            const wma60 = resolution === '1D'
                ? context.new_var(this.wma(close, Math.ceil(wmaLength / 5)))
                : context.new_var(this.wma(close, wmaLength));
            const stochastic60 = resolution === '1D'
                ? this.stochastic(Math.ceil(kdLength / 5), Math.ceil(rsvt / 5), Math.ceil(kt / 5))
                : this.stochastic(kdLength, rsvt, kt);
            const bbup = context.new_var(bband.top);
            wma.get(10);
            bbup.get(10);
            wma60.get(100);
            //#endregion
            //#region Score logics
            const conditionBBandUp = close.get(0) > bband.top;
            const conditionBBandDn = close.get(0) < bband.bottom;
            const conditionDailyKDUp = stochastic.k > stochastic.d;
            const conditionDailyKDDn = stochastic.k < stochastic.d;
            const condition60KDUp = stochastic60.k > stochastic60.d;
            const condition60KDDn = stochastic60.k < stochastic60.d;
            const conditionWmaDailyUp = close.get(0) > wma;
            const conditionWmaDailyDn = close.get(0) < wma;
            const conditionWma60Up = close.get(0) > wma60.get(0); //&& close.get(1) < wma60.get(1)
            const conditionWma60Dn = close.get(0) < wma60.get(0); //&& close.get(1) > wma60.get(1)
            if (resolution === '1D' || (resolution === '60' && this.isSessionLastBar(sessionType))) {
                if (conditionBBandUp) {
                    score += 1;
                }
                if (conditionBBandDn) {
                    score -= 1;
                }
                if (conditionDailyKDUp) {
                    score += 1;
                }
                if (conditionDailyKDDn) {
                    score -= 1;
                }
                if (conditionWmaDailyUp) {
                    score += 1;
                }
                if (conditionWmaDailyDn) {
                    score -= 1;
                }
                if (condition60KDUp) {
                    score += 1;
                }
                if (condition60KDDn) {
                    score -= 1;
                }
                if (conditionWma60Up) {
                    score += 1;
                }
                if (conditionWma60Dn) {
                    score -= 1;
                }
                console.log({
                    date: dayAPI(this.PineJS.Std.time(context)).format('MM/DD'),
                    score: score,
                    detail: {
                        BB: conditionBBandUp ? 1 : conditionBBandDn ? -1 : 0,
                        'WMA(D)': conditionWmaDailyUp ? 1 : conditionWmaDailyDn ? -1 : 0,
                        'WMA(60)': conditionWma60Up ? 1 : conditionWma60Dn ? -1 : 0,
                        'KD(D)': conditionDailyKDUp ? 1 : conditionDailyKDDn ? -1 : 0,
                        'KD(60)': condition60KDUp ? 1 : condition60KDDn ? -1 : 0,
                    },
                });
                _updateScore(score);
            }
            asia71178_store.buildUp = resolution === '1D' ? bbup.get(1) : bbup.get(6);
            asia71178_store.buildDn = resolution === '1D' ? wma.get(1) : wma.get(6);
            return [NaN];
        },
    },
    metainfo: {
        is_price_study: true,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [
            {
                id: 'None',
                type: 'line',
            },
        ],
        defaults: {
            precision: 2,
            inputs: {},
        },
        styles: {},
        inputs: [],
    },
});
